.radio-input {
  padding: 0.5rem;
}
.radio-input input {
  display: none;
}
.radio-input:hover {
  background-color: lightblue;
}
.radio-input.checked {
  outline: 1px solid orange;
}
